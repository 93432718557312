<template>
  <div class="wrapper">
    <section class="section-info section-info-sm" id="service-card">
      <div class="jcard-main jcard-dark">
        <!-- title -->
        <div class="jtitle">
          <h3 class="jtitle-icon"><i class="fad fa-envelope"></i></h3>
          <h3>Email Verification</h3>
        </div>

        <!-- card content -->
        <div class="text-center">
          <h4>Waiting for email verification.</h4>
          <div>Please check your mail at:</div>

          <div class="jcard-border mt-4">
            <p class="p1-platform p-grey">Email</p>
            <p class="p1-platform">{{ userMail }}</p>
          </div>
        </div>

        <!-- btn container -->
        <div class="d-flex mt-5">
          <div class="jbtn jbtn-white" @click="sendVerificationMail" :disabled="!resendButtonEnabled">
            Resend
          </div>
          <div v-if="showRefreshButton" class="jbtn jbtn-white ml-3" @click="$parent.reloadUserToCheckVerification">
            Refresh
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import toast from "@/assets/js/toast";
import C from "@/const";
import Authstore from "@/store/store.js";
import firebase from "@/firebase/firebaseInit";
const auth = firebase.auth;
const db = firebase.db;

export default {
  name: "VerifyEmail",
  data() {
    return {
      userMail: "",
      resendButtonEnabled: true,
      showRefreshButton: true
    };
  },
  methods: {
    init() {
      if (!auth.currentUser) return;

      this.userMail = auth.currentUser.email;
    },
    sendVerificationMail() {
      if (!auth.currentUser) {
        this.$router.replace({ name: "Login" });
        return;
      }

      this.resendButtonEnabled = false;
      var dashboardUrl = "";
      console.log("getting claims");
      Authstore.dispatch("getClaims").then(
        error => {
          console.log("geclaims done with error");
          if (error) console.log("In router: could not get claims" + error);

          toast.error("Something went wrong please try loggin in again.");
          return;
        },
        result => {
          if (result.member) {
            dashboardUrl = C.CLUB_URL + "member";
          } else if (result.press) {
            dashboardUrl = C.CLUB_URL + "press";
          } else {
            dashboardUrl = C.CLUB_URL + "owner";
          }

          console.log("SEND VERIFY MAIL");
          console.log(result);
          const emailTrigger = {
            type: C.EMAIL_TYPE.VERIFY,
            press: result.press,
            actionLink: dashboardUrl,
            email: auth.currentUser.email,
          };
          console.log();
          db.collection(C.COLLECTION.EMAILS).doc().set(emailTrigger).then(() => {
            toast.success("We have sent you a verification email. Please check your inbox.");
          })
            .catch(error => {
              toast.error("Sending verification email failed. " + error.message);
            });
        }
      );
    }
  },
  computed: {},
  created() {
    this.init();
  }
};
</script>

<style scoped></style>
