<template>
  <div>
    <EmailVerificationRequired v-if="!emailVerified"></EmailVerificationRequired>

    <div v-else class="wrapper-dashboard-info duke-style" id="dashboard-member">
      <div class="jcard-dark">
        <!-- <div>
          <p class="p4">Exclusive</p>
          <h1>Duke Club</h1>
        </div>
        <hr class="border" /> -->

        <div class="jtitle jtitle-80 text-center">
          <h3><small>Duke</small><br>MEMBER</h3>
          <div class="jsubtitle">
            <p id="p-text">
              <small
                ><strong>Welcome to DUKE MEMBER CLUB.</strong
                ><br />Here you have access to DUKE SHOP and a priority access to the news and novelties.</small
              >
            </p>
          </div>
        </div>

        <!-- Available soon -->
        <!-- <div>
          <div>
            <p class="mt-3 wht p ">
              Hello{{ userName }},
              <br />
              <strong>Exclusive DUKE content</strong> will be available soon.<br /><br />
            </p>
          </div>
          <br />

          <button type="button" class="jbtn jbtn-small mt-4" @click="logout()">
            <i class="fal fa-sign-in-alt"></i> Signout
          </button>
        </div> -->

        <!-- Content for meber -->

        <div class="grid-2">
          <router-link :to="{ name: 'Collection' }" class="jcard-duke">
            <i class="fal fa-watch fa-3x mb-3"></i>
            <h5>Shop</h5>
            <p class="m-0"><small>BECOME AN OWNER</small></p>
          </router-link>
          <router-link :to="{ name: 'NewsMember' }" class="jcard-duke">
            <i class="fal fa-newspaper fa-3x mb-3"></i>
            <h5>News</h5>
            <p class="m-0"><small>MEMBER CLUB</small></p>
          </router-link>
          <router-link :to="{ name: 'PaymentsMember' }" class="jcard-duke">
            <i class="fal fa-credit-card fa-3x mb-3"></i>
            <h5>Payments</h5>
            <p class="m-0"><small>MEMBER CLUB</small></p>
          </router-link>
          <a href="https://dukehorlogerie.com/contact" class="jcard-duke" target="_blank">
            <i class="fal fa-headset fa-3x mb-3"></i>
            <h5> Contact</h5>
            <p class="m-0"><i class="fal fa-link"></i></p>
          </a>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
// Component imports
import EmailVerificationRequired from '@/components/user/EmailVerificationRequired.vue';
// import Footer from "@/components/navigation/Footer";
// Logic imports
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const auth = firebase.auth;
const db = firebase.db;

export default {
  name: "DashboardMember",
  components: {
    EmailVerificationRequired
    // Footer: Footer,
  },
  data() {
    return {
      userName: "",
      emailVerified: auth.currentUser.emailVerified,
    };
  },
  methods: {
    // this can be called by the EmailVerification component to check for verification changes and hide the component if required
    // TODO: this is an antipattern => better to use events => emit from child to parent
    reloadUserToCheckVerification() {
      auth.currentUser.reload().then(() => {
        this.emailVerified = auth.currentUser.emailVerified;
        console.log("verification refresh: " );
        console.log(auth.currentUser.emailVerified);
      });
    },
    logout() {
      auth.signOut().then(() => {
        this.$router.replace({ name: "Login" });
      });
    }
  },
  beforeCreate() {
    db.collection(C.COLLECTION.USERS).doc(auth.currentUser.uid).get()
      .then(doc => {
        const docdata = doc.data();
        // additional leading space so that text does not look weird when user is not loaded yet
        this.userName = " " + docdata.firstName + " " + docdata.lastName;
      });

  }
};

</script>

<style scoped>
@import "../../assets/css/duke.css";
</style>
